import PersistenceTemplate from '@/modules/questionnaire/components/steps/questionnaire/pimples/persistence/PersistenceTemplate';

import { ACNE_DURATION, ACNE_FREQUENCY } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/Pimples/Persistence/PersistenceTemplate',
  component: PersistenceTemplate
};

const createStory = props => () => ({
  components: { PersistenceTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><persistence-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  acneDiscomfortFrequency: ACNE_FREQUENCY.TWO_TO_THREE_WEEKS,
  firstAcneOccurrence: ACNE_DURATION.UNDER_A_YEAR
});
